import styled from 'styled-components'
import NewsletterForm from './NewsletterForm'
import Input from '../ui/Input'

const Card = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.primary2};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.111424);
  padding: 40px;
  border-radius: 5px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  padding-bottom: 40px;

  ${props =>
    props.theme.bp.md(`
      grid-template-columns: 1fr;
  `)}
  ${props =>
    props.theme.bp.sm(`
      grid-template-columns: 1fr;
  `)}
`

const DrinkerNewsletterForm = () => (
  <Card>
    <NewsletterForm>
      <Grid>
        <Input variant="dark" label="First Name" name="FNAME" required />
        <Input variant="dark" label="Last Name" name="LNAME" required />
        <Input
          variant="dark"
          label="Email"
          name="EMAIL"
          required
          type="email"
        />
        <Input variant="dark" label="Phone" name="PHONE" required />
        <Input variant="dark" label="City" name="CITY" required />
        <Input variant="dark" label="State" name="STATE" required />
      </Grid>
      <input type="hidden" name="PERSONA" value="DRINKER" />
    </NewsletterForm>
  </Card>
)

export default DrinkerNewsletterForm
