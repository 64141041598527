import { useRef } from 'react'
import Link from 'next/link'

import MarketingSiteLayout from '../components/layouts/MarketingSiteLayout'
import DrinkerNewsletterForm from '../components/marketing/DrinkerNewsletterForm'
import RoundedButton from '../components/ui/RoundedButton'
import MultiText from '../components/ui/MultiText'
import Menu from '../components/marketing/Menu'
import MarketingFooter from '../components/marketing/MarketingFooter'
import * as styles from '../components/marketing/styles'
import * as illustrations from '../components/marketing/illustrations'

const Site = () => {
  const subscribeFormRef = useRef(null)

  function scrollToSubscribeForm() {
    subscribeFormRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <MarketingSiteLayout>
      <Menu activePage="DRINKER" hidePrimaryCTA={true} />
      <styles.SectionWrapper backgroundColor="primary1">
        <illustrations.BeerLoverTilesLeft />
        <illustrations.BeerLoverTilesRight />
        <illustrations.BeerLoverTable />
        <styles.HeroSectionContainer flexDirection="column">
          <div style={{ paddingBottom: '60px' }}>
            <MultiText
              boldText="HOPCoin is a Digital good for Token redeemable for one pint"
              regularText="of craft beer and loyalty points toward Brewer merch."
              variant="banner"
            />
            <RoundedButton
              variant="primary"
              label="Join the closed beta"
              onClick={scrollToSubscribeForm}
            />
          </div>
          <div>
            <illustrations.BeerLoverRedeem />
          </div>
        </styles.HeroSectionContainer>
      </styles.SectionWrapper>

      <styles.SectionWrapper backgroundColor="secondary2">
        <illustrations.BeerLoverCircles />
        <illustrations.Dots />
        <styles.SectionContainer flexDirection="column-reverse">
          <div>
            <MultiText
              boldText="Beer Lovers purchase HOPCoins online, in advance, for a discount"
              regularText="and redeem them in any participating Brewer’s Taproom."
              bodyText="Once HOPCoins are in your account Beer Lovers can gift HOPCoin to friends, and future friends, with a simple text.  A powerful way to slide into the DM."
              color="primary2"
              variant="section"
            />
          </div>
          <illustrations.BeerLoverDashboardPreview />
        </styles.SectionContainer>
      </styles.SectionWrapper>

      <styles.SectionWrapper backgroundColor="neutral4">
        <illustrations.BeerLoverSlats />
        <styles.SectionContainer>
          <illustrations.BeerLoverMerch />
          <div>
            <MultiText
              boldText="Beer Lovers can earn craft brewer merchandise"
              regularText="by redeeming enough HOPCoin at their favorite Brewery."
              bodyText="Participating Breweries decide what offers best suit their clients. Brewers may choose to offer glassware, hats, t-shirts, or even special events access after redeeming enough HOPCoin in their Taproom."
              color="primary2"
              variant="section"
            />
          </div>
        </styles.SectionContainer>
      </styles.SectionWrapper>

      <div ref={subscribeFormRef}>
        <styles.NewsletterSection maxWidth={750}>
          <MultiText
            boldText="Want to HOP on board?"
            regularText="Sign up to learn when HOPCoin arrives in your town at your breweries!"
            separateWith="newline"
            variant="section"
          />
          <DrinkerNewsletterForm />
        </styles.NewsletterSection>
      </div>

      <MarketingFooter />
    </MarketingSiteLayout>
  )
}

export default Site
